import React from "react"
import AppFooter from "../components/AppFooter/AppFooter"
// import AppHeader from "../components/AppHeader/AppHeader"
import AppHeader from "../components/V2/AppHeader"
import DomePledgeOfCivility from "../components/DomePledgeOfCivility/DomePledgeOfCivility"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"

const PledgeOfCivility: React.FC = () => {
  return (
    <div className=" w-full bg-lightBackground">
      <FaviconHeader />
      <AppHeader />
      <DomePledgeOfCivility />
      <AppFooter />
    </div>
  )
}

export default PledgeOfCivility
