import React, { useEffect, useState } from "react"
import BlackLogoDome from "./../../images/blackLogoDome.svg"
import WhiteLogoDome from "./../../images/whiteLogoDome.svg"
import HamBurger from "./../../images/hamBurger.svg"
import CloseIcon from "./../../images/closeIconPrimary.svg"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import { FontStyle } from "../SharedResources/Typography/typography.service"
import { navigate } from "gatsby"

interface AppHeaderProps {
  className?: string
  isWhiteLogo?: boolean
}
const AppHeader: React.FC<AppHeaderProps> = ({ className, isWhiteLogo }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleOpen = () => {
    setIsMenuOpen(true)
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden"
    }
  }

  const handleClose = () => {
    setIsMenuOpen(false)
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = ""
    }
  }

  useEffect(() => {
    window.addEventListener("resize", function () {
      var width = window.innerWidth
      if (width > 1024) {
        handleClose()
      }
    })
  })

  return (
    <div
      className={`sticky top-0 z-50 flex justify-between items-center w-full mr-2 px-8 py-6 ${
        !isMenuOpen && "opacity-95"
      } ${isWhiteLogo ? "" : "bg-white"}`}
    >
      <div
        className={`flex items-center space-x-2 cursor-pointer ${className} `}
        onClick={() => navigate("/")}
      >
        <img
          src={isWhiteLogo ? WhiteLogoDome : BlackLogoDome}
          alt="Logo"
          style={{ width: "36px", height: "36px" }}
          className={""}
        />

        <span
          className={`font-avertastd-bold text-2xl ${
            isWhiteLogo ? "text-white" : "text-black"
          }`}
        >
          DOME
        </span>
      </div>

      <div
        className={
          isMenuOpen
            ? `lg:hidden fixed top-0 left-0 bottom-0 right-0 bg-white flex flex-col items-center justify-center
          space-y-8`
            : `space-x-8 hidden lg:block`
        }
      >
        {isMenuOpen && (
          <img
            src={CloseIcon}
            alt="Close Icon"
            className="w-6  absolute top-8 right-6 cursor-pointer"
            onClick={handleClose}
          />
        )}
        <TextTypography
          fontStyle={FontStyle.BOLD}
          className="text-xl cursor-pointer"
          onClick={() => {
            navigate("/academics")
            handleClose()
          }}
        >
          Academics
        </TextTypography>
        <TextTypography
          fontStyle={FontStyle.BOLD}
          className="text-xl cursor-pointer"
          onClick={() => {
            navigate("/")
            handleClose()
          }}
        >
          Decision-makers
        </TextTypography>
        {/* <TextTypography
          fontStyle={FontStyle.BOLD}
          className="text-xl cursor-pointer"
        >
          Media
        </TextTypography> */}
        {/* <TextTypography
          fontStyle={FontStyle.BOLD}
          className="text-xl cursor-pointer"
          onClick={() => {
            navigate("/home/#advocates")
            handleClose()
          }}
        >
          Advocates
        </TextTypography> */}
        <TextTypography
          fontStyle={FontStyle.BOLD}
          className="text-xl cursor-pointer"
          onClick={() => {
            navigate("/apply-now")
            handleClose()
          }}
        >
          Apply Now
        </TextTypography>
      </div>
      <img
        src={HamBurger}
        alt="Hamburger icon"
        className="w-8 cursor-pointer lg:hidden"
        onClick={handleOpen}
      />
    </div>
  )
}
export default AppHeader
