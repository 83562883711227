import React from "react"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../SharedResources/Typography/typography.service"
import "../PrivacyPolicy/Policy.scss"

const DomePledgeOfCivility = () => {
  return (
    <main className="w-full body-policy">
      <section className="flex justify-center bg-blueShade1">
        <article className="flex flex-col items-center max-w-700 xl-mobile:px-8">
          <TextTypography
            className="mt-12 text-2xl sm:text-4xl"
            fontStyle={FontStyle.BOLD}
          >
            What is Dome's Pledge of Civility?
          </TextTypography>
          <TextTypography className="mt-12 text-base">
            Dome is free of the uncivil discourse that dominates clashes over
            policy on other social media platforms. The reason is we only admit
            people who pledge to participate in a non-toxic manner.
          </TextTypography>
          <TextTypography className="text-base mt-2">
            Dome requires each person to agree to our Pledge of Civility before
            they can join the platform and participate in any Dome activities,
            on the app, online, or in person:
          </TextTypography>
          <div className="w-full mb-4 mt-12">
            <div className="w-full bg-greyDefault rounded-t-lg p-3 font-avertastd-bold flex">
              <TextTypography color={TextColor.WHITE}>
                "When I am inside the Dome community,
              </TextTypography>
            </div>
            <div className="w-full bg-white rounded-b-lg">
              <ul className="list-disc px-10 p-6">
                <li>
                  <TextTypography>
                    I promise not to personally attack any other person,
                  </TextTypography>
                </li>
                <li>
                  <TextTypography>
                    I promise not to impugn the motives of any other person, and
                  </TextTypography>
                </li>
                <li>
                  <TextTypography>
                    I promise not to use toxic or vulgar expressions."
                  </TextTypography>
                </li>
              </ul>
            </div>
          </div>
        </article>
      </section>
    </main>
  )
}

export default DomePledgeOfCivility
