import * as React from "react"
import WhiteLogoDome from "./../../images/whiteLogoDome.svg"
import FooterLogoDome from "./../../images/footerDomeTop.svg"
import { Link } from "gatsby"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import { TextColor } from "../SharedResources/Typography/typography.service"

const AppFooter: React.FC = () => {
  const linkBaseClasses = `text-base !text-white opacity-60 hover:opacity-100 font-averta-std-regular`
  return (
    <div className={"landing-page w-full relative z-10 mt-40"}>
      <img
        src={FooterLogoDome}
        className={"footer-top-curve w-full"}
        alt="Footer Top Curve"
      />
      <div className={"bg-darkBlue footer-content"}>
        <footer className="py-8 container-fluid">
          <main className="container">
            <article className="pb-8 row">
              <section className="mb-8 col-md-6 ">
                <div className="flex flex-row items-center mt-6 cursor-pointer">
                  <Link to="/" className="flex items-center space-x-2">
                    <img
                      src={WhiteLogoDome}
                      alt="Logo"
                      style={{ width: "36px", height: "36px" }}
                      className={""}
                    />
                    <span
                      style={{ fontSize: "22px" }}
                      className="text-white  font-avertastd-bold"
                    >
                      DOME
                    </span>
                  </Link>
                </div>
                <div className="mt-2">
                  <TextTypography
                    color={TextColor.WHITE}
                    className="opacity-60"
                  >
                    © 2023 Dome Compass, Inc.
                  </TextTypography>
                </div>
              </section>
              <section className="mb-8 col-md-2">
                <div className="mt-6 font-semibold text-white font-averta-std-regular text-base">
                  Company
                </div>
                <div className="mt-3">
                  <Link to="/about" className={linkBaseClasses}>
                    About
                  </Link>
                </div>
                <div className="mt-3">
                  <Link to="/values" className={linkBaseClasses}>
                    Values
                  </Link>
                </div>
              </section>
              <section className="mb-8 col-md-2">
                <div className="mt-6 font-semibold text-white font-averta-std-regular text-base">
                  Privacy
                </div>
                <div className="mt-3">
                  <Link to="/pledge-of-civility" className={linkBaseClasses}>
                    Pledge of Civility
                  </Link>
                </div>
                <div className="mt-3">
                  <Link to="/privacy-policy" className={linkBaseClasses}>
                    Privacy Policy
                  </Link>
                </div>
                {/* <div className="mt-3 ">
                  <Link to="/cookie-policy" className={linkBaseClasses}>
                    Cookie Policy
                  </Link>
                </div> */}
                <div className="mt-3 ">
                  <Link to="/terms-of-service" className={linkBaseClasses}>
                    Terms of Service
                  </Link>
                </div>
              </section>
              <section className="mb-8 col-md-2">
                <div className="mt-6 font-semibold text-white font-averta-std-regular text-base">
                  App
                </div>
                <div className="mt-3">
                  <Link className={linkBaseClasses} to="/sign-in">
                    Sign In
                  </Link>
                </div>
                <div className="mt-3">
                  <a
                    className={linkBaseClasses}
                    href={`mailto:privacy@domecompass.com`}
                    target={`_blank`}
                    rel={"noreferrer noopener"}
                  >
                    Join
                  </a>
                </div>
              </section>
            </article>
          </main>
        </footer>
      </div>
    </div>
  )
}

export default AppFooter
